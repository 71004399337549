<template>
  <div class="Screen">
    <div class="title-top">
      <div class="title"><img :src="require('@/assets/images/bigScreen/title.png')" /></div>
    </div>
    <div class="content">
      <div class="min-box">
        <div class="in-box">
          <div class="in-title">{{first.title}}</div>
          <div class="in-cont" id="pie"></div>
        </div>
        <div class="in-box">
          <div class="in-title">{{second.title}}</div>
          <div class="in-cont">
            <div class="in-cont-box" style="height: 100%;overflow-y:auto;">
              <div  v-for="(item, index) in second.data" :key="index">
                <div class="bar-title">{{item.label}}</div>
                <div class="bar-box">
                  <div class="progress">
                    <div class="bar" :style="{width: item.data / 20000 * 100 + '%'}"></div>
                  </div>
                  <div class="">{{item.data}}{{second.unit}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="min-box">
        <div class="in-box">
          <div class="in-title">{{third.title}}</div>
          <div class="in-cont" id="chart2"></div>
        </div>
        <div class="in-box">
          <div class="in-title">{{four.title}}</div>
          <div class="in-cont third" id="chart3"></div>
          <div class="bottom-list">
              <div class="item-box" v-for="(item, index) in four.list" :key="index">
                <div class="title">{{item.label}}</div>
                <div class="data">{{item.value}}</div>
              </div>
            </div>
        </div>
      </div>
      <div class="min-box">
        <div class="in-box long">
          <div class="in-title">{{five.title}}</div>
          <div class="top">
            <div class="value">{{five.data.value}}</div>
            <div class="label">{{five.data.label}}</div>
            <div class="line"></div>
          </div>
          <div class="in-cont" id="chart4">
          </div>
        </div>
      </div>
      <div class="big-box">
        <mapLine/>
        <div class="top">
          <div class="item-box" v-for="(item, index) in mapData.topList" :key="index">
            <div class="label">{{item.label}}</div>
            <div class="value">{{item.value}}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="item-box" v-for="(itemOut, index) in mapData.bottomList" :key="'out' + index">
            <div class="item-line" v-for="(item, indexIn) in itemOut" :key="'in'+indexIn">
              <div class="label">{{item.label}}</div>
              <div class="value">{{item.value}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="min-box">
        <div class="in-box long">
          <div class="in-title">{{six.title}}</div>
          <div class="list-box">
            <div class="list-item" v-for="(item, index) in six.data" :key="index">
              <div class="value">{{item.value}}</div>
              <div class="label">{{item.label}}</div>
            </div>
            <div class="line"></div>
          </div>
          <div class="in-cont list-cont">
            <div class="list-item" v-for="(item, index) in six.list" :key="index">
              <div class="">{{item.name}}</div>
              <div class="bar-box">
                <div class="progress" :style="{width: item.value / six.max * 10.9 + 'rem'}"></div>
                <div class="">{{item.value}}{{six.unit}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="min-box">
        <div class="in-box">
          <div class="in-title">{{seven.title}}</div>
          <div class="in-cont">
            <div class="seven">
              <div class="label">{{seven.label}}</div>
              <div class="value">{{seven.value}}</div>
              <div class="scale">{{seven.scale}}</div>
            </div>
          </div>
        </div>
        <div class="in-box">
          <div class="in-title">{{eight.title}}</div>
          <div class="in-cont eight">
            <div class="item-box" v-for="(item, index) in eight.data" :key="index">
              <div class="label">{{item.label}}</div>
              <div class="value">{{item.value}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="min-box">
        <div class="in-box">
          <div class="in-title">{{nine.title}}</div>
          <div class="in-cont" id="chart5"></div>
        </div>
        <div class="in-box">
          <div class="in-title">{{ten.title}}</div>
          <div class="in-cont">
            <div class="table">
              <div class="table-head">
                <div class="td">面料名称</div>
                <div class="td">均价（元）</div>
                <div class="td">面料名称</div>
                <div class="td">均价（元）</div>
              </div>
              <div class="table-body">
                <div class="tr" v-for="(item, index) in ten.list" :key="index">
                  <div class="td">{{item.list1}}</div>
                  <div class="td">{{item.list2}}</div>
                  <div class="td">{{item.list3}}</div>
                  <div class="td">{{item.list4}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import mapLine from './components/mapLine.vue'
export default {
  name: 'bigScreen',
  components: {mapLine},
  data(){
    return{
      fontSize: 20,
      mapData: {
        topList: [
          {
            label: '河南省童装店铺数量',
            value: '55215家'
          },
          {
            label: '全国童装店铺数量',
            value: '499746家'
          },
          {
            label: '山东省童装店铺数量',
            value: '45912家'
          }
        ],
        bottomList: [
          [
            {label: '江苏省童装店铺：',value:'36929'},
            {label: '广东省童装店铺：',value:'32570'},
            {label: '安徽省童装店铺：',value:'31313'}
          ],
          [
            {label: '四川省童装店铺：',value:'31304'},
            {label: '河北省童装店铺：',value:'30283'},
            {label: '浙江省童装店铺：',value:'27248'}
          ],
          [
            {label: '湖南省童装店铺：',value:'23404'},
            {label: '湖北省童装店铺：',value:'19279'},
            {label: '江西省童装店铺：',value:'16044'}
          ]
        ]
      },
      first: {
        title: '供应链需求品牌方占比',
        unit: '%',
        data: [
          {
            name: '贸易批发',
            value: 38.3,
            color: 'green'
          },
          {
            name: '品牌代工',
            value: 27.6,
            color: 'blue'
          },
          {
            name: '网销电销',
            value: 24.3,
            color: 'yellow'
          },
          {
            name: '其他',
            value: 19.7,
            color: 'white'
          }
        ]
      },
      second: {
        title: '供应链月度产能趋势排行',
        unit: '个',
        data: [
          { label: '4月', data: 19200 },
          { label: '5月', data: 18000 },
          { label: '6月', data: 17000 },
          { label: '7月', data: 12000 },
          { label: '8月', data: 15000 },
          { label: '9月', data: 18000 },
          { label: '10月', data: 16000 },
        ]
      },
      third: {
        title: '供应链成交量趋势',
        xAxis: ['1月', '2月', '3月', '4月', '5月','6月'],
        data: [{
          name: '2023年年度数值',
          unit: '订单金额（万元）',
          data: [500,200,400,322,111,666]
        },
        {
          name: '2022',
          uint: '单位2',
          data: [5,6,7,8,4,9]
        }]
      },
      four: {
        title: '供应链产能分类情况',
        data: [{
          name: '针织类',
          value: 2000,
          max: 3000
        },{
          name: '毛革与皮革',
          value: 2000,
          max: 3000
        },{
          name: '其他类',
          value: 1500,
          max: 3000
        },{
          name: '复合类',
          value: 1000,
          max: 3000
        },{
          name: '化纤类',
          value: 2000,
          max: 3000
        },{
          name: '梭织类',
          value: 1200,
          max: 3000
        }],
        list: [{
          label: '现已入驻需求企业',
          value: 2723
        },{
          label: '现已入驻加工企业',
          value: 1469
        }]
      },
      five: {
        title: '网络营销数据一览',
        data: {
          label: '抖音热销童装总销量',
          value: 1734593
        },
        chartData: {
          title: '微博流量趋势数据图',
          unit: '单位',
          xAxis: ['12月5日', '12月6日', '12月7日', '12月8日', '12月9日'],
          data: [
            {
              name: '当日博主数',
              data: [1000,2000,3000,1000,2000]
            },
            {
              name: '当日转发数',
              data: [4000,2000,4000,5000,1000]
            },
            {
              name: '当日点赞数',
              data: [2000,3000,1000,2222,1000]
            }
          ]
        }
      },
      six: {
        title: '用工人群画像来源地区排行',
        data: [
          {label: '2023累计求职人数', value: 29735},
          {label: '2023累计发布岗位数', value: 110514}
        ],
        unit: '人',
        max: 10000,
        list: [
          {name: '安徽', value: 8477},
          {name: '贵州', value: 7357},
          {name: '河南', value: 4021},
          {name: '云南', value: 2028},
          {name: '浙江', value: 1366},
          {name: '江西', value: 1305},
          {name: '江苏', value: 956},
          {name: '四川', value: 890},
          {name: '湖北', value: 549},
          {name: '湖南', value: 453},
        ]
      },
      seven: {
        title: '本月发布岗位',
        value: 6242,
        label: '同比下降',
        scale: '26.35%'
      },
      eight: {
        title: '岗位人才分布排行',
        data: [
          {label: '烫工', value: 7611},
          {label: '后道包装', value: 1669},
          {label: '裁剪工', value: 4437},
          {label: '车工', value: 1510},
          {label: '临时杂工', value: 2160},
          {label: 'CAD版师', value: 1063}
        ]
      },
      nine: {
        title: '集采需求趋势',
        chartData: {
          unit: '单位',
          xAxis: ['12月5日', '12月6日', '12月7日', '12月8日', '12月9日'],
          data: [
            {
              name: '实际成交',
              data: [999,888,555,222,1300,211]
            },
            {
              name: '市场报价',
              data: [333,222,444,222,111,555]
            }
          ]
        }
      },
      ten: {
        title: '采集物料平均单价',
        list: [
          {list1: '高弹力牛仔 ', list2: 12.24, list3: '高弹力牛仔 ', list4: 12.24},
          {list1: '汗布', list2: 34.40, list3: '梭织平纹 ', list4: 11.95},
          {list1: '拉架', list2: 31.26, list3: '记忆面料/仿记忆 ', list4: 11.13},
          {list1: '针织提花 ', list2: 22.00, list3: '法兰绒 ', list4: 11.08},
          {list1: '灯芯绒 ', list2: 16.59, list3: '梭织弹力 ', list4: 10.53},
          {list1: '梭织斜料 ', list2: 16.03, list3: '针织牛仔 ', list4: 10.4},
          {list1: '人造棉/粘胶 ', list2: 14.99, list3: '蕾丝 ', list4: 9.69},
          {list1: '尼丝纺/尼龙 ', list2: 13.38, list3: '雪纺 ', list4: 9.62}
        ]
      },
      colorList: {
        green:  new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#00F7FF'
          },
          {
            offset: 1,
            color: '#008A83'
          }
        ]),
        blue:  new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#01349B'
          },
          {
            offset: 1,
            color: '#387BFC'
          }
        ]),
        lightBlue: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#00A8FF'
          },
          {
            offset: 1,
            color: '#8FDFFE'
          }
        ]),
        darkBlue: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 1,
            color: '#88D0CF'
          },
          {
            offset: 0,
            color: '#2744D5'
          }
        ]),
        yellow: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#FEEF4D'
          },
          {
            offset: 1,
            color: '#857700'
          }
        ]),
        lightYellow: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 1,
            color: 'rgba(208, 195, 51, 0)'
          },
          {
            offset: 0,
            color: 'rgba(215, 201, 51, 1)'
          }
        ]),
        white: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#A8A8A8'
          },
          {
            offset: 1,
            color: '#FFFFFF'
          }
        ]),
        radarColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(21, 192, 255, 0.1)'
          },
          {
            offset: 1,
            color: 'rgba(21, 192, 255, 0.6)'
          }
        ])
      }
    }
  },
  mounted(){
    // 计算基础font-size
    console.log(window.innerWidth)
    let fontSize = window.innerWidth / 1104 * 10
    document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px'
    this.fontSize = fontSize;
    setTimeout(() => {
      this.pieChart();
      this.drwChart2();
      this.radarChart();
      this.drwChart4();
      this.drwChart5();
    }, 500)
    /* this.$nextTick(() => {
      this.pieChart();
      this.drwChart2();
      this.radarChart();
      this.drwChart4();
      this.drwChart5();
    }) */
  },
  methods: {
    pieChart(){
      let data = [];
      let colorList = this.colorList;
      this.first.data.map(item => {
        data.push({
          name: item.name,
          value: item.value,
          itemStyle: {
            color: colorList[item.color]
          }
        })
      })
      var myChart = echarts.init(document.getElementById('pie'));
      // 指定图表的配置项和数据
      var option = {
        // symbol: 'circle',
        legend: {
          textStyle: {
              color: '#fff',
              fontSize: 0.4 * this.fontSize + 'px',
              lineHeight: 1.4 * this.fontSize 
          },
          right:0,
          bottom: '10%',
          orient: 'vertical',
          formatter: (name) => {
            return name
          }
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: ['18%', '80%'],
            startAngle: 135,
            label: {show: false},
            labelLine: {show: false},
            center: ['25%', '50%'],
            roseType: 'area',
            data: data
          }
        ]
      };
      myChart.setOption(option);
    },
    drwChart2(){
      var myChart = echarts.init(document.getElementById('chart2'));
      let data = this.third;
      let option = {
        legend:{
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 0.3* this.fontSize + 'px',
          },
          right: 0,
          top: '5%'
        },
        grid: {
            left: '0',
            right: '3%',
            bottom: '0',
            containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data.xAxis,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#04BBFF',
                opacity: 0.6
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            boundaryGap: ['5%', '5%']
          }
        ],
        yAxis: [
          {
            type: 'value',
            // scale: true,
            min: 0,
            // axisLine: false,
            splitLine: { 
              show:false, 
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            /* splitLine: { 
              show:true, 
              width: 1,
              lineStyle: {color: '#04BBFF'}
            }, */
            name: data.data[0].unit,
            nameTextStyle: {
              color: '#fff',
              align:'left'
            },
          },
          {
            type: 'value',
            // scale: true,
            min: 0,
            axisLine: false,
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            splitLine: { 
              show:false, 
            },
            name: data.data[1].unit,
            nameTextStyle: {color: '#fff',align:'right'},
          }
        ],
        series: [
          {
            name: data.data[0].name,
            type: 'bar',
            yAxisIndex: 0,
            barWidth: 0.7 * this.fontSize + 'px',
            data: data.data[0].data,
            itemStyle: {
                color: this.colorList.lightBlue,
                opacity: 0.4
            }
          },
          {
            name: data.data[1].name,
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            showSymbol: false,
            data: data.data[1].data,
            itemStyle: {
                color: this.colorList.lightYellow
            },
            areaStyle: {
              opacity: 0.8,
              color: this.colorList.lightYellow
            },
          }
        ]
      }
      myChart.setOption(option);
    },
    radarChart(){
      var myChart = echarts.init(document.getElementById('chart3'));
      let indicator = [];
      let data = []
      this.four.data.map(item => {
        indicator.push({
          name: item.name,
          max: item.max
        })
        data.push(item.value)
      })
      let option = {
        radar: {
          // shape: 'circle',
          indicator: indicator,
          startAngle: 120,
          splitArea: {
              show: false,
              areaStyle: {
                  color: "rgba(255,0,0,0)", // 图表背景的颜色
              },
          },
          splitLine: {
            lineStyle: {
              width: 1,
              color: "#fff",
              opacity: 0.4
            }
          }
        },
        
        series: [
          {
            type: 'radar',
            data: [{
              value:data
            }],
            symbolSize: this.fontSize * 0.3 + 'px',
            itemStyle: {
              color: '#15C0FF'
            },
            label: {
              fontSize: this.fontSize * 0.3 + 'px',
              fontWeight: 400,
              color: '#fff'
            },
            areaStyle: {
              color: this.colorList.radarColor
            },
          }
        ]
      }
      myChart.setOption(option);
    },
    drwChart4(){
      var myChart = echarts.init(document.getElementById('chart4'));
      let chartData = this.five.chartData;
      let data = {
        xAxis: chartData.xAxis,
        unit: chartData.unit,
        data: chartData.data
      }
      let option = {
        legend:{
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 0.3* this.fontSize + 'px',
          },
          right: 0,
          top: '5%'
        },
        grid: {
            left: '0',
            right: '3%',
            bottom: '0',
            containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: data.xAxis,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#04BBFF',
                opacity: 0.6
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            boundaryGap: ['5%', '5%']
          }
        ],
        yAxis: [
          {
            type: 'value',
            // scale: true,
            splitLine: { 
              show:false, 
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            min: 0,
            axisLine: false,
            name: data.unit,
            nameTextStyle: {
              color: '#fff',
              align:'left'
            },
          }
        ],
        series: [
          {
            name: data.data[0].name,
            type: 'bar',
            barWidth: 0.9 * this.fontSize + 'px',
            data: data.data[0].data,
            itemStyle: {
                color: this.colorList.darkBlue,
                opacity: 0.4
            }
          },
          {
            name: data.data[1].name,
            type: 'line',
            data: data.data[1].data,
            itemStyle: {
                color: '#F9D778'
            },
          },
          {
            name: data.data[2].name,
            type: 'line',
            data: data.data[2].data,
            itemStyle: {
                color: '#FF954E'
            },
          }
        ]
      }
      myChart.setOption(option);
    },
    drwChart5(){
      var myChart = echarts.init(document.getElementById('chart5'));
      let data = {}
      let chartData = this.nine.chartData;
      data.unit = chartData.unit;
      data.xAxis = chartData.xAxis;
      data.data = chartData.data
      let option = {
        legend:{
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 0.3* this.fontSize + 'px',
          },
          right: 0,
          top: '5%'
        },
        grid: {
            left: '0',
            right: '3%',
            bottom: '0',
            containLabel: true
        },
         xAxis: [
          {
            type: 'category',
            data: data.xAxis,
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: '#04BBFF',
                opacity: 0.6
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            boundaryGap: ['5%', '5%']
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            min: 0,
            axisLine: false,
            splitLine: { 
              show:false, 
            },
            axisLabel: {
              color: '#fff',
              fontSize: 0.3 * this.fontSize
            },
            name: data.unit,
            nameTextStyle: {
              color: '#fff',
              align:'left'
            },
          }
        ],
        series: [
          {
            name: data.data[0].name,
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: data.data[0].data,
            itemStyle: {
                color: '#FFFC00'
            }
          },
          {
            name: data.data[1].name,
            type: 'line',
            smooth: true,
            showSymbol: false,
            data: data.data[1].data,
            itemStyle: {
                color: '#4FF8FF'
            }
          }
        ]
      }
      myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="less">
@baseUrl: '../assets/images/bigScreen';
.Screen{
  width: 100%;
  height: 100%;
  background: url('@{baseUrl}/bg.png') no-repeat;
  background-size: 100% 100%;
  font-weight: bold;
  text-align: left;
  img{
    display: block;
  }
  .big-box{
    position: relative;
    padding-top: 1.8rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
    .top, .bottom{
      position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    .top{
      top: 1.7rem;
      .item-box{
        width: 6.8rem;
        height: 1.7rem;
        padding-top: 0.3rem;
        box-sizing: border-box;
        background: url('@{baseUrl}/map-top.png') no-repeat;
        background-size: 100% 100%;
        text-align: center;
        .label{
          font-size: 0.4rem;
        }
        .value{
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
    }
    .bottom{
      bottom: 1.8rem;
      .item-line{
        display: flex;
        justify-content: center;
        line-height: 0.9rem;
      }
      .item-box{
        background: url('@{baseUrl}/map-data-bg.png') no-repeat;
        background-size: 100% 100%;
        width: 6.2rem;
        height: 3.3rem;
        font-size: 0.4rem;
        box-sizing: border-box;
        padding: 0.3rem;
        .value{
          font-size: 0.6rem;
          font-weight: bold;
          color: #24B2EC;
        }
      }
    }
  }
  .title-top{
    background: url('@{baseUrl}/title-bg.png') no-repeat;
    background-size: 100% 100%;
    height: 2.2rem;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    .title{
      height: 2rem;
      width: 18rem;
      margin: 0.2rem auto 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .content{
    justify-content: space-around;
    padding-top: 2.2rem;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    .min-box{
      width: 13.3rem;
      height: 100%;
      .in-box{
        height: 50%;
        position: relative;
        &.long{
          height: 100%;
          .in-cont{
            padding-top: 12.5rem;
          }
          .list-cont{
            padding-top: 8.5rem;
          }
        }
      }
    }
    .big-box{
      width: 25.1rem;
      height: 100%;
    }
  }
  .min-box{
    .in-title{
      font-size: 0.5rem;
      padding-left: 0.9rem;
      position: relative;
      background: url('@{baseUrl}/in-title-bt.png') no-repeat left bottom;
      background-size: 100% auto;
      height: 1.4rem;
      line-height: 1.4rem;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      box-sizing: border-box;
      &::before{
        content: '';
        display: block;
        background: url('@{baseUrl}/title-icon.png') no-repeat;
        background-size: 100% 100%;
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        left: 0;
        bottom: 0.3rem;
      }
      &::after{
        content: '';
        display: block;
        background: url('@{baseUrl}/in-title-bg.png') no-repeat;
        background-size: 100% 100%;
        width: 6.1rem;
        height: 0.3rem;
        position: absolute;
        right: 0;
        bottom: 0.6rem;
      }
    }
    .bottom-list{
      height: 2.2rem;
      position: absolute;
      left: 0;
      bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-around;
      .item-box{
        width: 6.1rem;
        text-align: center;
        height: 2.2rem;
        &:first-child{
          background: url('@{baseUrl}/blue-bg.png') no-repeat;
          background-size: 100% 100%;
        }
        &:last-child{
          background: url('@{baseUrl}/yellow-bg.png') no-repeat;
          background-size: 100% 100%;
        }
        .title{
          font-size: 0.4rem;
          line-height: 1.2rem;
        }
        .data{
          font-size: 0.7rem;
          line-height: 0.7rem;
          font-weight: bold;
        }
      }
    }
    .in-cont{
      height: 100%;
      padding-top: 1.9rem;
      padding-bottom: 1rem;
      box-sizing: border-box;
      font-size: 0.4rem;
      &.third{
        padding-bottom: 3.2rem;
      }
      .in-cont-box{
        height: 100%;
        overflow-y: auto;
        >div:nth-of-type(2n) .progress .bar{
          background: #65E3AC;
        }
        >div:nth-of-type(2n+1) .progress .bar{
          background: #0084FF;
        }
      }
    }
  }
  .bar-box{
    justify-content: space-between;
    display: flex;
    align-items: center;
    .progress{
      flex-grow: 1;
      background: #393B6D;
      height: 0.4rem;
      width: 0;
      transition: 1s all;
      position: relative;
      .bar{
        height: 0.1rem;
        position: absolute;
        left: 0;
        top: 0;
        width:0;
        transition: all 1s;
        &::after{
          content: '';
          display: block;
          background: url('@{baseUrl}/progress-icon.png') no-repeat;
          background-size: 100% 100%;
          width: 0.9rem;
          height: 0.9rem;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%,-50%);
        }
      }
    }
  }
  .list-cont{
    .list-item:nth-of-type(3n) .progress{
      background: #2AC8FA;
    }
    .list-item:nth-of-type(3n+1) .progress{
      background: #176998;
    }
    .list-item:nth-of-type(3n+2) .progress{
      background: #44754D;
    }
    .list-item{
      margin-bottom: 0.3rem;
      .bar-box{
        justify-content: flex-start;
        .progress{
          flex-grow:0;
          margin-right: 0.3rem;
        }
      }
    }
  } 
  .in-box.long .top{
    background: url('@{baseUrl}/data-bg.png') no-repeat;
    background-size: 100% 100%;
    width: 9.9rem;
    height: 7.6rem;
    padding-top: 3.6rem;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    top: 2.4rem;
    left: 50%;
    margin-left: -4.95rem;
    // margin: 1rem auto 2rem;
    .value{
      color: #4EDDFE;
      font-size: 1.6rem;
      font-weight: bold;
      position: relative;
      line-height: 1.1rem;
      margin-bottom: 2.8rem;
      &::after{
        content: '';
        position: absolute;
        display: block;
        width: 0.9rem;
        height: 1rem;
        background: url('@{baseUrl}/to-top.png') no-repeat;
        background-size: 100% 100%;
        left: 50%;
        margin-left: -0.45rem;
        top: 1.9rem;
      }
    }
    .label{
      font-size: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .line{
    width: 100%;
    height: 0.1rem;
    background: url('@{baseUrl}/line.png') no-repeat;
    background-size: 100% 100%;
  }
  .list-box{
    padding: 0.5rem 0 0.6rem;
    display: flex;
    justify-content: space-around;
    left: 0;
    width: 100%;
    top: 1.4rem;
    position: absolute;
    flex-wrap: wrap;
    .line{
      margin-top: 0.6rem;
    }
    .list-item{
      width: 5.2rem;
      height: 5.2rem;
      text-align: center;
      padding-top: 2rem;
      box-sizing: border-box;
      &:first-of-type{
        background: url('@{baseUrl}/list-bg1.png') no-repeat;
        background-size: 100% 100%;
        .value{
          color: #00FFF1;
        }
      }
      &:nth-of-type(2){
        background: url('@{baseUrl}/list-bg2.png') no-repeat;
        background-size: 100% 100%;
        .value{
          color: #FF8400;
        }
      }
      .value{
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 0.8rem;
        margin-bottom: 0.3rem;
      }
      .label{
        font-size: 0.3rem;
        opacity: 0.65;
      }
    }
  }
  .seven{
    width: 13.4rem;
    height: 7.1rem;
    background: url('@{baseUrl}/seven-bg.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 1.5rem;
    position: relative;
    padding-top: 2.8rem;
    box-sizing: border-box;
    .value{
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }
    .label, .scale{
      position: absolute;
      top: 50%;
      width: 2.7rem;
      height: 1.4rem;
      margin-top: -0.7rem;
      background: url('@{baseUrl}/seven-label.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 1.4rem;
      font-size: 0.58rem;
    }
    .scale{
      right: 0;
    }
  }
  .eight{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    .item-box{
      width: 40%;
      position: relative;
      padding-left: 2.9rem;
      padding-top: 0.4rem;
      box-sizing: border-box;
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 2.4rem;
        height: 1.4rem;
        background: url('@{baseUrl}/eight-icon.png') no-repeat;
        background-size: 100% 100%;
        left: 0;
        top:0;
      }
      .label{
        background: url('@{baseUrl}/eight-bg.png') no-repeat left bottom;
        background-size: 100% auto;
        width: 2.4rem;
        height: 0.4rem;
        line-height: 0.4rem;
        margin-bottom: 0.3rem;
      }
    }
  }
  .table{
    .table-head{
      color: #00A4FA;
      font-weight: bold;
      line-height: 1.2rem;
      font-size: 0.4rem;
      display: flex;
      .td{
        border-bottom: 0.1rem solid #00A4FA;
      }
    }
    .td{
      margin-right: 0.2%;
    }
    .td:nth-of-type(2n){
      width: 19.8%;
    }
    .td:nth-of-type(2n+1){
      width: 29.8%;
    }
    .tr{
      height: 0.8rem;
      line-height: 0.8rem;
      display: flex;
      &:nth-of-type(2n){
        background: url('@{baseUrl}/table-tr.png') no-repeat;
        background-size: 100% 100%;
      }
      &:nth-of-type(2n+1){
        line-height: 1.2rem;
        height: 1.2rem;
      }
    }
  }
}
</style>
